import { useRef, useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { CloseIcon, SimpleCheckmarkIcon } from 'src/assets';
import { iconBox, inputField, StyledEditTitleBox, spanFiledStyle } from './style';

type Props = {
  value: string;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  iconSize: number;
  greenColor: string;
  onCloseEditMode: () => void;
  toggleEditHandler: (title: string) => void;
};

export const EditTitleBox = ({
  value,
  iconSize,
  greenColor,
  onCloseEditMode,
  toggleEditHandler,
}: Props) => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const [title, setTitle] = useState<string>(value);
  const [inputWidth, setInputWidth] = useState(0);

  useEffect(() => {
    setTitle(value);
    if (spanRef.current) {
      setInputWidth(spanRef.current.offsetWidth);
    }
  }, [value]);

  useEffect(() => {
    const keydownEvent = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        toggleEditHandler(title);
      }

      if (event.key === 'Escape' || event.key === 'Esc') {
        onCloseEditMode();
      }
    };

    document.addEventListener('keydown', keydownEvent);
    return () => {
      document.removeEventListener('keydown', keydownEvent);
    };
  }, [toggleEditHandler, title, onCloseEditMode]);
  return (
    <StyledEditTitleBox>
      <TextField
        sx={inputField}
        value={title}
        onChange={(event) => {
          setTitle(event.target.value.slice(0, 255));
        }}
        autoFocus
        variant="standard"
        inputProps={{
          style: {
            width: `${inputWidth}px`,
            boxSizing: 'border-box',
          },
          'data-testid': 'edit-title-input-field',
        }}
        InputProps={{
          disableUnderline: true,
          sx: {
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: 'none',
            },
          },
        }}
      />
      <Box component="span" ref={spanRef} sx={spanFiledStyle}>
        {value}
      </Box>
      <Box sx={iconBox} onClick={onCloseEditMode} data-sm="edit-title-close-icon">
        <CloseIcon width={18} height={12} />
      </Box>
      <Box
        sx={iconBox}
        onClick={() => toggleEditHandler(title)}
        data-sm="edit-title-checkmark-icon"
      >
        <SimpleCheckmarkIcon width={iconSize} height={iconSize} fill={greenColor} />
      </Box>
    </StyledEditTitleBox>
  );
};
